import { ZERO_WEI } from '@kwenta/sdk/constants'
import { formatDollars, getDisplayAsset } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import { DEFAULT_PERPS_PROVIDER } from 'constants/futures'
import { selectAllMarkPriceInfos, selectMarketsByProvider } from 'state/futures/common/selectors'

import { selectPreviousDayPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'

export const selectMarketsWithPriceInfo = createSelector(
	selectMarketsByProvider,
	selectAllMarkPriceInfos,
	selectPreviousDayPrices,
	(marketsByProvider, pricesByProvider, pastPrices) => {
		const markets = marketsByProvider[DEFAULT_PERPS_PROVIDER]
		const markPrices = pricesByProvider[DEFAULT_PERPS_PROVIDER]

		return markets.map((m) => {
			const pastPrice = pastPrices.find(
				(price) => price.synth === getDisplayAsset(m.asset)?.toUpperCase()
			)
			const basePriceRate = markPrices[m.asset]

			const change =
				basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
					? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
					: ZERO_WEI

			return {
				asset: m.asset,
				price: formatDollars(basePriceRate?.price ?? '0', {
					suggestDecimalsForAsset: m.asset,
				}),
				change: change.toNumber(),
				priceDirection: basePriceRate?.change ?? null,
				isMarketClosed: m.isSuspended,
				closureReason: m.marketClosureReason,
			}
		})
	}
)

export const selectMirrorPosts = (state: RootState) => state.home.mirrorPosts
