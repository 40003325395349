import { PerpsProvider } from '@kwenta/sdk/types'
import { useCallback } from 'react'

import { MAX_TOTAL_TRADES } from 'constants/defaults'
import {
	fetchCrossMarginAccountLiquidations,
	fetchPerpsV3GlobalLiquidations,
} from 'state/futures/snxPerpsV3/actions'
import { selectSnxV3Account, selectV3SelectedMarketId } from 'state/futures/snxPerpsV3/selectors'
import { useAppSelector, useFetchAction, usePollAction } from 'state/hooks'
import { fetchPreviousDayPrices } from 'state/prices/actions'
import {
	fetchAllReferralData,
	fetchBoostNftForAccount,
	fetchBoostNftMinted,
} from 'state/referrals/action'
import {
	fetchClaimableRewards,
	fetchClaimableRewardsParams,
	fetchEstimatedRewards,
	fetchFeesPaid,
	fetchReferralFeesPaid,
	fetchStakeMigrateData,
	fetchStakingData,
} from 'state/staking/actions'
import { selectEpochPeriod, selectSelectedEpoch } from 'state/staking/selectors'
import { selectAbstractionAddress, selectIsWatcherMode, selectWallet } from 'state/wallet/selectors'
import { providerIsCrossMargin } from 'utils/futures'

import { DEFAULT_PERPS_PROVIDER } from 'constants/futures'
import {
	fetchAbstractionDelegateStatus,
	fetchAccountData,
	fetchAvgOneClickTxCost,
	fetchDashboardChartData,
	fetchDelegatesForAccount,
	fetchFuturesPositionHistory,
	fetchGlobalTradeHistory,
	fetchHighFrequencyAccountData,
	fetchLowFrequencyAccountData,
	fetchMarketData,
	fetchOrderHistory,
	fetchPerpsAccounts,
	fetchSubAccountsForAccount,
	fetchTradeHistory,
} from './actions'
import {
	selectMarketAsset,
	selectMarkets,
	selectPerpsProvider,
	selectProviderNetwork,
} from './common/selectors'
import { fetchFuturesFees, fetchFuturesFeesForAccount } from './isolatedMargin/actions'
import { selectSnxV2Account } from './isolatedMargin/selectors'
import { selectAccountContext } from './selectors'

// TODO: Optimise polling and queries
export const usePollMarketFuturesData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const markets = useAppSelector(selectMarkets)
	const wallet = useAppSelector(selectWallet)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const smartMarginAddress = useAppSelector(selectSnxV2Account)
	const crossMarginAccount = useAppSelector(selectSnxV3Account)
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const selectedMarket = useAppSelector(selectV3SelectedMarketId)
	const selectedMarketAsset = useAppSelector(selectMarketAsset)
	const { accountId } = useAppSelector(selectAccountContext)

	useFetchAction(fetchBoostNftMinted, {
		dependencies: [wallet],
		disabled: isWatcherMode,
		name: 'fetchBoostNftMinted',
	})

	useFetchAction(fetchBoostNftForAccount, {
		dependencies: [wallet],
		disabled: isWatcherMode,
		name: 'fetchBoostNftForAccount',
	})

	const fetchAccounts = useCallback(
		() => fetchPerpsAccounts({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [wallet, selectedPerpsProvider],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	const fetchDelegates = useCallback(
		() => fetchDelegatesForAccount([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchDelegates, {
		dependencies: [wallet, smartMarginAddress, selectedPerpsProvider],
		disabled: !wallet || !smartMarginAddress,
		name: 'fetchDelegates',
	})

	const fetchSubAccounts = useCallback(
		() => fetchSubAccountsForAccount([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchSubAccounts, {
		dependencies: [wallet, smartMarginAddress, selectedPerpsProvider],
		disabled: !wallet || !smartMarginAddress,
		name: 'fetchSubAccounts',
	})

	const fetchGlobal = useCallback(
		() => fetchGlobalTradeHistory({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	usePollAction('fetchGlobalTradeHistory', fetchGlobal, {
		dependencies: [selectedPerpsProvider, selectedMarketAsset],
	})

	usePollAction('fetchPerpsV3GlobalLiquidations', fetchPerpsV3GlobalLiquidations, {
		dependencies: [selectedPerpsProvider, selectedMarket],
		disabled: !providerIsCrossMargin(selectedPerpsProvider),
	})

	const fetchMarketDataAction = useCallback(
		() => fetchMarketData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchMarketData', fetchMarketDataAction, {
		dependencies: [selectedPerpsProvider],
		intervalTime: 60000,
	})

	const fetchAllMarketDataAction = useCallback(
		() =>
			fetchMarketData([
				PerpsProvider.PERENNIAL_V2_ARB,
				PerpsProvider.SNX_V2_OP,
				PerpsProvider.SNX_V3_BASE,
			]),
		[]
	)

	useFetchAction(fetchAllMarketDataAction, {
		dependencies: [],
		name: 'fetchAllMarketDataAction',
	})

	const fetchHighFrequencyAccountDataAction = useCallback(
		() => fetchHighFrequencyAccountData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchHighFrequencyAccountData', fetchHighFrequencyAccountDataAction, {
		intervalTime: 15000,
		dependencies: [markets.length, selectedPerpsProvider, accountId],
		disabled: !markets.length,
	})

	const fetchLowFrequencyAccountDataAction = useCallback(
		() => fetchLowFrequencyAccountData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchLowFrequencyAccountData', fetchLowFrequencyAccountDataAction, {
		intervalTime: 60000,
		dependencies: [markets.length, selectedPerpsProvider, accountId],
		disabled: !markets.length,
	})

	const fetchPositionHistoryAction = useCallback(
		() => fetchFuturesPositionHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)
	usePollAction('fetchFuturesPositionHistory', fetchPositionHistoryAction, {
		intervalTime: 15000,
		dependencies: [accountId],
		disabled: !wallet,
	})

	const fetchTradeHistoryAction = useCallback(
		() => fetchTradeHistory({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	usePollAction('fetchTradeHistry', fetchTradeHistoryAction, {
		dependencies: [networkId, selectedPerpsProvider, accountId],
		disabled: !wallet,
		intervalTime: 30000,
	})

	usePollAction('fetchCrossMarginAccountLiquidations', fetchCrossMarginAccountLiquidations, {
		dependencies: [networkId, crossMarginAccount, selectedPerpsProvider],
		intervalTime: 30000,
		disabled: !crossMarginAccount || !providerIsCrossMargin(selectedPerpsProvider),
	})

	const fetchOrderHistoryAction = useCallback(
		() => fetchOrderHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchOrderHistory', fetchOrderHistoryAction, {
		dependencies: [networkId, wallet, crossMarginAccount, selectedPerpsProvider],
		intervalTime: 30000,
		disabled: !wallet,
	})
}

export const usePollDashboardFuturesData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const markets = useAppSelector(selectMarkets)
	const wallet = useAppSelector(selectWallet)
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const { accountId } = useAppSelector(selectAccountContext)

	const fetchAccounts = useCallback(
		() => fetchPerpsAccounts({ providers: [selectedPerpsProvider] }),
		[selectedPerpsProvider]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [networkId, wallet, selectedPerpsProvider],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	const fetchMarketDataAction = useCallback(
		() => fetchMarketData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchMarketData', fetchMarketDataAction, {
		dependencies: [selectedPerpsProvider],
		intervalTime: 60000,
	})

	const fetchPosHistory = useCallback(
		() => fetchFuturesPositionHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchFuturesPositionHistory', fetchPosHistory, {
		intervalTime: 15000,
		dependencies: [wallet, accountId],
		disabled: !wallet,
	})

	const fetchAccountDataAction = useCallback(
		() => fetchAccountData([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchAccountData', fetchAccountDataAction, {
		intervalTime: 30000,
		dependencies: [markets.length, accountId],
		disabled: !markets.length,
	})

	const fetchTradeHistoryAction = useCallback(
		() => fetchTradeHistory({ providers: [selectedPerpsProvider], pageSize: MAX_TOTAL_TRADES }),
		[selectedPerpsProvider]
	)

	usePollAction('fetchTradeHistory', fetchTradeHistoryAction, {
		dependencies: [networkId, wallet, selectedPerpsProvider, accountId],
		intervalTime: 30000,
		disabled: !wallet,
	})

	usePollAction('fetchDashboardChartData', fetchDashboardChartData, {
		dependencies: [accountId, selectedPerpsProvider],
		intervalTime: 30000,
	})

	const fetchOrderHistoryAction = useCallback(
		() => fetchOrderHistory([selectedPerpsProvider]),
		[selectedPerpsProvider]
	)

	usePollAction('fetchOrderHistory', fetchOrderHistoryAction, {
		dependencies: [accountId, selectedPerpsProvider],
		intervalTime: 30000,
		disabled: !wallet,
	})
}

export const useFetchRewardsData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const wallet = useAppSelector(selectWallet)
	const { start, end } = useAppSelector(selectSelectedEpoch)
	const fetchFuturesFeesAction = useCallback(
		() => fetchFuturesFees([PerpsProvider.PERENNIAL_V2_ARB, PerpsProvider.SNX_V2_OP]),
		[]
	)

	const fetchFuturesFeesForAccountAction = useCallback(
		() => fetchFuturesFeesForAccount([PerpsProvider.PERENNIAL_V2_ARB, PerpsProvider.SNX_V2_OP]),
		[]
	)

	const fetchAccounts = useCallback(
		() =>
			fetchPerpsAccounts({
				providers: [PerpsProvider.SNX_V2_OP],
			}),
		[]
	)

	useFetchAction(fetchAccounts, {
		dependencies: [networkId, wallet],
		disabled: !wallet,
		name: 'fetchAccounts',
	})

	useFetchAction(fetchStakingData, {
		dependencies: [networkId, wallet],
		name: 'fetchStakingData',
	})

	useFetchAction(fetchEstimatedRewards, {
		dependencies: [networkId, wallet],
		name: 'fetchEstimatedRewards',
	})

	useFetchAction(fetchFeesPaid, {
		dependencies: [networkId, wallet],
		name: 'fetchFeesPaid',
	})

	useFetchAction(fetchReferralFeesPaid, {
		dependencies: [networkId, wallet],
		name: 'fetchReferralFeesPaid',
	})

	useFetchAction(fetchClaimableRewards, {
		dependencies: [networkId, wallet],
		name: 'fetchClaimableRewards',
	})

	useFetchAction(fetchClaimableRewardsParams, {
		dependencies: [networkId, wallet],
		name: 'fetchClaimableRewardsParams',
	})

	useFetchAction(fetchFuturesFeesAction, {
		dependencies: [networkId, start, end],
		name: 'fetchFuturesFees',
	})

	useFetchAction(fetchFuturesFeesForAccountAction, {
		dependencies: [networkId, wallet, start, end],
		disabled: !wallet,
		name: 'fetchFuturesFeesForAccount',
	})
}

export const useFetchStakeMigrateData = () => {
	const networkId = useAppSelector(selectProviderNetwork)
	const wallet = useAppSelector(selectWallet)
	const { start, end } = useAppSelector(selectSelectedEpoch)
	const fetchFuturesFeesSnxV2 = useCallback(() => fetchFuturesFees([PerpsProvider.SNX_V2_OP]), [])
	const fetchFuturesFeesForAccountSnxV2 = useCallback(
		() => fetchFuturesFeesForAccount([PerpsProvider.SNX_V2_OP]),
		[]
	)
	useFetchAction(fetchStakeMigrateData, {
		dependencies: [networkId, wallet],
		name: 'fetchStakeMigrateData',
	})

	useFetchAction(fetchFuturesFeesSnxV2, {
		dependencies: [networkId, start, end],
		name: 'fetchFuturesFeesSnxV2',
	})

	useFetchAction(fetchFuturesFeesForAccountSnxV2, {
		dependencies: [networkId, wallet, start, end],
		disabled: !wallet,
		name: 'fetchFuturesFeesForAccountSnxV2',
	})
}

export const useFetchReferralData = () => {
	const wallet = useAppSelector(selectWallet)
	const period = useAppSelector(selectEpochPeriod)

	useFetchAction(fetchAllReferralData, {
		name: 'fetchAllReferralData',
		dependencies: [wallet, period],
		disabled: !wallet || !period,
	})
}

export const useFetchAbstractionData = () => {
	const aaAddress = useAppSelector(selectAbstractionAddress)
	const { provider, network, accountId } = useAppSelector(selectAccountContext)

	useFetchAction(fetchAbstractionDelegateStatus, {
		dependencies: [accountId, aaAddress, network],
		disabled: !accountId,
		name: 'fetchAbstractionDelegateStatus',
	})

	usePollAction('futures/fetchAvgOneClickTxConst', fetchAvgOneClickTxCost, {
		dependencies: [accountId, aaAddress, network],
		intervalTime: 3 * 60 * 1000, // 3 minutes
		disabled: !aaAddress || provider === PerpsProvider.PERENNIAL_V2_ARB,
	})
}

export const useFetchLandingPageMarketData = () => {
	const fetchMarketDataAction = useCallback(() => fetchMarketData([DEFAULT_PERPS_PROVIDER]), [])

	useFetchAction(fetchMarketDataAction, {
		dependencies: [],
		name: 'fetchMarketData',
	})

	useFetchAction(fetchPreviousDayPrices, {
		dependencies: [],
		name: 'fetchPreviousDayPrices',
	})
}
